import React, { useState } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => {
    const [darkMode, setDarkMode] = useState(1);
    const [audio, setAudio] = useState(1);

    const sw = {
        setDarkMode,
        setAudio,
        values: { darkMode, audio }
    };

    return (
        <Layout sw={sw}>
            <SEO title="404: Not found" />
            <h1>FUCK!</h1>
            <p>
                you lost. get yo ass back <a href="/">home</a>.
            </p>
        </Layout>
    );
};

export default NotFoundPage;
